// core
import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappWrapperComponentProps } from 'components'

// styles
import * as css from './ExpandableBox.scss'

export const ExpandableBoxContext = createContext<() => void>(() => {
  // do nothing
})

export interface IExpandableBoxProps extends IDotappWrapperComponentProps {
  /**
   * Whethr box should be expanded
   */
  expanded: boolean
  /**
   * Use also fade animation
   */
  fade?: boolean
  /**
   * Direction to which the box should be expanded
   */
  direction?: 'topToBottom' | 'bottomToTop'
}

export function ExpandableBox({
  expanded,
  fade,
  direction = 'topToBottom',
  children,
  className,
}: IExpandableBoxProps) {
  const contentRef = useRef<HTMLDivElement>(null)
  const refreshParent = useContext(ExpandableBoxContext)

  const refresh = useCallback(() => {
    const childHeight = contentRef.current?.children[0].scrollHeight || 0
    const duration = contentRef.current ? 0.2 + contentRef.current.scrollHeight / 5000 : 0.3

    if (contentRef.current) {
      const contentElement = contentRef.current

      contentElement.style.height = expanded ? `${childHeight}px` : '0'

      contentElement.style.visibility = expanded ? 'visible' : 'hidden'
      let transition = expanded
        ? `height ${duration}s ease-in`
        : `height ${duration}s ease-out, visibility 0s linear ${duration}s`

      if (fade) {
        contentElement.style.opacity = expanded ? '1' : '0'
        transition += expanded
          ? `, opacity ${duration}s linear ${0.2 * duration}s`
          : `, opacity ${0.8 * duration}s linear`
      }

      contentElement.style.transition = transition
    }

    setTimeout(refreshParent, duration * 1000 + 100)
  }, [contentRef, !!expanded, refreshParent])

  useEffect(() => {
    refresh()
  }, [!!expanded])

  return (
    <div ref={contentRef} className={classnames(css.root, css[direction], className)}>
      <div>
        <ExpandableBoxContext.Provider value={refresh}>{children}</ExpandableBoxContext.Provider>
      </div>
    </div>
  )
}
