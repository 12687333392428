// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/HelveticaNeue-Thin.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/HelveticaNeue-ThinItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/fonts/HelveticaNeue-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/fonts/HelveticaNeue-LightItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/fonts/HelveticaNeue.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../assets/fonts/HelveticaNeue-Italic.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:300}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:300;font-style:italic}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:400}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");font-weight:400;font-style:italic}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");font-weight:500}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-weight:500;font-style:italic}.SidePanel_backdrop_1Iad_{z-index:100}.SidePanel_root_ExU4t{background-color:#fff;overflow-y:auto;position:fixed;top:0;bottom:0;left:0;display:flex;flex-direction:column;transition:margin-left .3s ease,margin-right .3s ease;max-width:100%;z-index:200}.SidePanel_root_ExU4t.SidePanel_left_2CujD{left:0;border-radius:0 10px 10px 0}.SidePanel_root_ExU4t.SidePanel_left_2CujD.SidePanel_open_12WbF{margin-left:0}.SidePanel_root_ExU4t.SidePanel_right_3ElZB{right:0;border-radius:10px 0 0 10px}.SidePanel_root_ExU4t.SidePanel_right_3ElZB.SidePanel_open_12WbF{margin-right:0}.SidePanel_root_ExU4t.SidePanel_inverted_2zia_{background-color:#252525}.SidePanel_sidePanelHeader_juq5M{display:flex;align-items:center;height:50px;margin:10px 15px 10px 30px;color:#bfbfbf;flex-shrink:0}.SidePanel_sidePanelHeader_juq5M h4{flex:1;font-size:16px;font-weight:500;line-height:20px;color:#252525;margin:0}.SidePanel_sidePanelHeader_juq5M .SidePanel_closeButton_13i6_{margin-right:10px}\n", ""]);
// Exports
exports.locals = {
	"backdrop": "SidePanel_backdrop_1Iad_",
	"root": "SidePanel_root_ExU4t",
	"left": "SidePanel_left_2CujD",
	"open": "SidePanel_open_12WbF",
	"right": "SidePanel_right_3ElZB",
	"inverted": "SidePanel_inverted_2zia_",
	"sidePanelHeader": "SidePanel_sidePanelHeader_juq5M",
	"closeButton": "SidePanel_closeButton_13i6_"
};
module.exports = exports;
