// mobx
import { action, observable } from 'mobx'
// others
import queryString from 'query-string'
import { RouteComponentProps } from 'react-router'
// routing
import { IRoutes } from 'routes'

export interface IActiveSideMenu {
  /**
   * Name of the sidebar, add new if you need more, always leave undefined as a option
   */
  name: 'nav' | 'notifications' | 'search' | 'profileControls' | 'filter' | undefined
  /**
   * Side which is the menu rolled-out from.
   */
  side: 'left' | 'right' | undefined
  /**
   * Width of the sidebar wrapper in pixels.
   */
  width: number
}

class LayoutStoreUI {
  // VARS
  navigation?: RouteComponentProps

  routes: IRoutes[] = []

  @observable
  activeSideMenu: IActiveSideMenu = { name: undefined, side: undefined, width: 0 }

  @observable
  disableLocalSeach = false

  @observable
  activeRoutePath = '/'

  // ACTIONS
  @action.bound
  setSideMenu = (
    name: 'nav' | 'notifications' | 'search' | 'profileControls' | 'filter' | undefined,
    side: 'left' | 'right' | undefined,
    width: number,
    reset?: boolean
  ) => {
    // store to the temp var, just so we can compare later
    const lastUsedName = this.activeSideMenu.name
    // first reset everything
    this.activeSideMenu.name = undefined
    this.activeSideMenu.side = undefined
    this.activeSideMenu.width = 0
    // if another nav should be opened, open it
    if (lastUsedName !== name && !reset) {
      this.activeSideMenu.name = name
      this.activeSideMenu.side = side
      this.activeSideMenu.width = width
    }
  }

  @action.bound
  getSearchSuggestions = () =>
    // searchTerm: string
    {
      // const response = {
      //   searchValue: searchTerm,
      //   results: [
      //     {
      //       id: 1,
      //       title: 'Websupport',
      //       in: 'Invoices',
      //     },
      //     {
      //       id: 2,
      //       title: 'Webdesign',
      //       in: 'Project Documentation',
      //     },
      //     {
      //       id: 3,
      //       title: 'WebExpo',
      //       in: 'Events',
      //     },
      //     {
      //       id: 4,
      //       title: 'Dataweb Website',
      //       in: 'Projects',
      //     },
      //   ],
      // }
      // this.searchSuggestions = searchTerm.length > 3 ? response : { searchValue: '', results: [] }
      // return searchTerm.length > 3 ? this.searchSuggestions : { searchValue: '', results: [] }
    }

  /**
   * (Un)Sets a query param of given key/name to the desired value, removes it from the URL if no value specified.
   * @param key Key of the value.
   * @param value The new value of the query param.
   */
  setQueryParam = (key: string, value?: string | null) => {
    if (this.navigation) {
      // get old query string
      const values = queryString.parse(decodeURI(this.navigation.location.search))
      // check if the value even exists
      if (values && values[key]) {
        // if there are more than one values, only use the first one, this also removes duplicities (speaking of keys, not values)
        if (Array.isArray(values[key])) {
          values[key] = (values[key] || '')[0].toString()
        }
      }
      // stringify old query string for later comparation
      const stringifiedOld = queryString.stringify(values)
      // if there is a value, set it
      if (!!value && value !== '' && value.trim().length > 0) {
        values[key] = value
      }
      // if no value or empty string or whitespace-only string, consider that unset was desired
      else {
        delete values[key]
      }
      // stringify new
      const stringifiedNew = queryString.stringify(values)
      // check if value was updated or not
      if (stringifiedNew !== stringifiedOld) {
        this.navigation.history.push({
          search: `?${encodeURI(stringifiedNew)}`,
        })
      }
    }
  }

  /**
   * Get a value of a query param.
   * @param key The key of the value.
   */
  getQueryParam = (key: string) => {
    return this.navigation
      ? queryString.parse(decodeURI(this.navigation.location.search))[key]
      : undefined
  }

  /**
   * Deletes duplicities of keys in query string, only uses the first occurence.
   */
  fixQueryParams = () => {
    if (this.navigation) {
      const values = queryString.parse(decodeURI(this.navigation.location.search))
      const stringifiedOld = queryString.stringify(values)

      Object.keys(values).forEach((i) => {
        // if duplicities
        if (Array.isArray(values[i])) {
          // try to find the first usable value
          for (let it = 0; it < (values[i] || '').length; it++) {
            if (
              values[i] &&
              (values[i] || '')[it] !== '' &&
              (values[i] || '')[it].trim().length > 0
            ) {
              values[i] = (values[i] || '')[it]
              break
            }
          }
          // if still array, that means no usable values => can be deleted
          if (Array.isArray(values[i])) delete values[i]
        }
        // if is not array, check if usable, if not, delete
        else if (
          !(values[i] && values[i] !== '' && (values[i] || '').toString().trim().length > 0)
        ) {
          delete values[i]
        }
      })
      // if any changes occured, rerender, if none, then not
      const stringifiedNew = queryString.stringify(values)
      if (stringifiedOld !== stringifiedNew) {
        this.navigation.history.push({
          search: `?${encodeURI(stringifiedNew)}`,
        })
      }
    }
  }
}

export const LayoutStore = new LayoutStoreUI()
