// core
import React, { ReactNode } from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { Icon, IconSize } from 'components/basic/Icon/Icon'
import { Image } from 'components/basic/Image/Image'

// styles
import * as css from './Avatar.scss'

export type AvatarSize = 'pico' | 'nano' | 'micro' | 'mini' | 'small' | 'normal' | 'large' | 'huge'

export interface IAvatarProps extends IDotappComponentProps {
  /**
   * Avatar image url. It will overwrite icon prop
   */
  url?: string | null
  /**
   * Use icon as avatar
   */
  icon?: string | null
  /**
   * Avatar size
   */
  size?: AvatarSize
  /**
   * Pass children to avatar. Highest priority - it willl overwrite url and icon prop.
   */
  children?: ReactNode
}

export const Avatar = ({ url, icon, size = 'normal', children, className }: IAvatarProps) => {
  const iconSizeMap = {
    huge: 'extraLarge',
    large: 'large',
    micro: 'mini',
    mini: 'small',
    nano: 'mini',
    normal: 'medium',
    small: 'small',
  }

  const content =
    children ||
    (url ? (
      <Image src={url} />
    ) : icon ? (
      <Icon name={icon} size={size ? (iconSizeMap[size] as IconSize) : undefined} />
    ) : null)

  return <div className={classnames(css.root, css[size], className)}>{content}</div>
}
