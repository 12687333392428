// core
import React, { lazy, memo } from 'react'

import { Redirect, Route, Switch } from 'react-router-dom'
// components
import { IRoutes } from 'routes/index'
import { LoggedRoutes } from 'routes/LoggedRoutes'

// data types
import { MenuItemFragment } from 'queries/Layout/types/MenuItemFragment'

// stores
import { LayoutStore } from '../LayoutStore'

// routes
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'))
const Dev = lazy(() => import('pages/Dev/Dev'))
const ButtonElements = lazy(() => import('pages/Dev/Button'))
const FormElements = lazy(() => import('pages/Dev/FormElements'))
const SpecialElements = lazy(() => import('pages/Dev/SpecialElements'))
const Chips = lazy(() => import('pages/Dev/Chips'))
const PopupMenus = lazy(() => import('pages/Dev/PopupMenus'))
const DevTabs = lazy(() => import('pages/Dev/Tabs'))
const Wysiwyg = lazy(() => import('pages/Dev/Wysiwyg'))
const CreateBeaconZone = lazy(() => import('pages/Smartcity/CreateBeaconZone'))
const BeaconZone = lazy(() => import('pages/Smartcity/BeaconZone'))
const CreateNewUser = lazy(() => import('pages/UserManagement/CreateNewUser'))
const UserProfile = lazy(() => import('pages/UserManagement/UserProfile'))
const SystemMenu = lazy(() => import('pages/SystemMenu/SystemMenu'))
const IotApp = lazy(() => import('pages/IotApps/IotApp'))
const Gift = lazy(() => import('pages/Gifts/Gift'))
const News = lazy(() => import('pages/News/NewsDetail'))
const Banners = lazy(() => import('pages/Banners/Banners'))
const Page404 = lazy(() => import('pages/Dev/Page404'))
const LivestreamManagement = lazy(() => import('pages/LivestreamManagement/LivestreamManagement'))
const LivestreamManage = lazy(() => import('pages/LivestreamManagement/LivestreamManage'))

// const Help = () => (
//   <div>
//     <h1>Help</h1>
//   </div>
// )

export interface IRouterProps {
  /**
   * List of routes
   */
  routes: (MenuItemFragment & { subMenu: any[] })[]
}

export const Router = memo(function Router({ routes }: IRouterProps) {
  const renderRoutes = (items: (MenuItemFragment & { subMenu: any[] })[]): any => {
    return items
      .map((item) => {
        if (item.subMenu && item.subMenu.length > 0) {
          return renderRoutes(item.subMenu)
        }

        if (item.url) {
          const itemUrl = `/${item.url}`
          const route: IRoutes | undefined = LoggedRoutes.find((r) => r.path === itemUrl)

          return route ? (
            <Route
              key={itemUrl}
              exact
              path={itemUrl}
              render={(props) => {
                const Component = route.component

                LayoutStore.navigation = props

                // @ts-ignore
                return <Component {...props} />
              }}
            />
          ) : null
        }

        return null
      })
      .reduce((routes, item) => routes.concat(Array.isArray(item) ? item : [item]), [])
  }

  return (
    <Switch>
      {renderRoutes(routes)}
      <Route exact component={Dashboard} path="/" />
      <Route exact component={CreateNewUser} path="/new-user" />
      <Route exact component={UserProfile} path="/user/:id" />
      <Route exact component={Gift} path="/gift/:id" />
      <Route exact component={CreateBeaconZone} path="/new-beacon-zone" />
      <Route exact component={BeaconZone} path="/beacon-zone/:id" />
      <Route exact component={IotApp} path="/iot-application/:id" />
      <Route exact component={SystemMenu} path="/menu" />
      <Route exact component={LivestreamManagement} path="/livestream-events" />
      <Route exact component={LivestreamManage} path="/livestream-event/:id" />
      <Route exact component={LivestreamManage} path="/livestream-event" />
      {/* <Route exact component={Help} path="/help" /> */}
      <Route exact component={Dev} path="/dev/dev" />
      <Route exact component={ButtonElements} path="/dev/button" />
      <Route exact component={FormElements} path="/dev/form-elements" />
      <Route exact component={SpecialElements} path="/dev/special-elements" />
      <Route exact component={Chips} path="/dev/chips" />
      <Route exact component={PopupMenus} path="/dev/popup-menus" />
      <Route exact component={DevTabs} path="/dev/tabs" />
      <Route exact component={Wysiwyg} path="/dev/wysiwyg" />
      <Route exact component={News} path="/news-articles/:id" />
      <Route exact component={Banners} path="/banners" />
      <Route exact component={Page404} path="/dev/page404" />

      <Route exact path="/login">
        <Redirect to="" />
      </Route>
      <Route exact path="/set-new-password">
        <Redirect to="" />
      </Route>
    </Switch>
  )
})
