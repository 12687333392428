// core
import React, { MouseEvent, useCallback } from 'react'

import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { Popup } from 'components/complex/Popup/Popup'

// styles
import * as css from './FormFieldValidationLabel.scss'

export interface IFormFieldValidationLabelProps extends IDotappComponentProps {
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Whether the field is valid
   */
  valid: boolean
  /**
   * Optional error message which will be shown in popup
   */
  errorMessage?: string // TODO: use translated string
}

/**
 * Label which dispplay validation result as icon and some error message in popup
 */
export const FormFieldValidationLabel = ({
  inverted,
  valid,
  errorMessage,
  className,
}: IFormFieldValidationLabelProps) => {
  const classNameString = classnames(css.root, valid ? css.valid : css.invalid, className)

  const handleClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  return !valid && errorMessage ? (
    <Popup
      className={classnames(classNameString, css.popup)}
      color="danger"
      heading={errorMessage}
      icon="info_outlined"
      inverted={inverted}
    >
      <a href="#" onClick={handleClick}>
        <div className={css.label} />
      </a>
    </Popup>
  ) : (
    <div className={classNameString}>
      <div className={css.label} />
    </div>
  )
}
