// core
import React, { ChangeEvent, useCallback } from 'react'

// components
import { Icon } from 'components/basic/Icon/Icon'
import { getTranslation } from 'components/complex/Translation/Translation'
import { IInputProps, Input } from 'components/form/Input/Input'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './SearchInput.scss'

export interface ISearchInputChildrenArgs {}

export interface ISearchInputProps extends Omit<IInputProps, 'onChange'> {
  /**
   * Callback to run on input value change
   */
  onChange?: (value: string) => void
}

export function SearchInput({ onChange, ...props }: ISearchInputProps) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      runCallback(onChange, event.target.value)
    },
    [onChange]
  )

  return (
    <Input
      classes={{ input: css.input, inputWrap: css.inputWrap }}
      className={css.root}
      leftRender={<Icon className={css.icon} name="search" />}
      placeholder={getTranslation('general.search_something')}
      {...props}
      onChange={handleChange}
    />
  )
}
