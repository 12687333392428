// core
import React, { ReactNode } from 'react'

// libraries
import classnames from 'classnames'

// components
import { ILoadingProps, Loading } from 'components/basic/Loading/Loading'

// styles
import * as css from './LoadingWrapper.scss'

export interface ILoadingWrapperProps extends ILoadingProps {
  /**
   * Whether display loading indicator instead of children
   */
  loading: boolean
  /**
   * Children to render if not loading
   */
  children?: ReactNode | null
}

export const LoadingWrapper = ({
  loading,
  children = null,
  className,
  ...passingProps
}: ILoadingWrapperProps) => {
  return (
    <>
      {loading ? (
        <div className={classnames(css.root, className)}>
          <Loading {...passingProps} />
        </div>
      ) : (
        children
      )}
    </>
  )
}
