// core
import React from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'

// styles
import * as css from './Loading.scss'

export interface ILoadingProps extends IDotappComponentProps {
  /**
   * Set loader color
   */
  color?: 'primary' | 'inherit'
  /**
   * Set loader size
   */
  size?: 'default' | 'large'
}

export const Loading = ({ color, size, className }: ILoadingProps) => {
  return (
    <div
      className={classnames(
        `spinner-border`,
        css.root,
        color === 'primary' ? css.primaryColor : '',
        size === 'large' ? css.large : '',
        className
      )}
    />
  )
}
