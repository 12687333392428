// core
import React, { isValidElement, ReactElement, ReactNode, useState } from 'react'

import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { Icon } from 'components/basic/Icon/Icon'
import { Popover } from 'components/complex/Popover/Popover'
import { ITranslationProps, Translation } from 'components/complex/Translation/Translation'

// styles
import * as css from './Popup.scss'

export interface IPopupProps extends IDotappComponentProps {
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Color of popup heading and icon
   */
  color?: 'neutral' | 'primary' | 'success' | 'warning' | 'danger'
  /**
   * Popup heading
   */
  heading: string | ReactElement<ITranslationProps> // TODO: remove string
  /**
   * Popup content
   */
  content?: ReactNode
  /**
   *
   */
  variant?: 'normal' | 'small'
  /**
   *
   */
  icon?: string
  /**
   * Children component - usually Button
   */
  children: ReactElement<any>
}

export function Popup({
  inverted,
  color,
  heading,
  content,
  className,
  children,
  variant = 'normal',
  icon,
  classes = {},
}: IPopupProps) {
  const [open, setOpen] = useState<boolean>(false)

  const handleHover = (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    setOpen(true)
  }

  const handleBlur = () => {
    setOpen(false)
  }

  const contentComponent =
    typeof content === 'string' ||
    (content && isValidElement(content) && content.type === Translation) ? (
      <p>{content}</p>
    ) : (
      content
    )

  return (
    <div
      className={classnames(css.root, className)}
      onBlur={handleBlur}
      onFocus={handleHover}
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}
    >
      {React.cloneElement(children, {
        className: children.props.className,
        onClick: handleHover,
      })}

      <Popover
        backdrop={false}
        classes={{
          content: css.content,
          modal: css.modal,
          paper: classnames(css.paper, css[variant]),
          popover: classnames(
            css.popoverComponent,
            color ? css[color] : css.neutral,
            classes.popoverComponent
          ),
        }}
        className={css.popover}
        inverted={inverted}
        open={open}
        side="right"
        width={variant === 'small' ? 130 : 200}
      >
        <div className={css.headingWrap}>
          {icon ? <Icon name={icon} size={variant === 'small' ? 'mini' : 'small'} /> : null}

          <h4>{heading}</h4>
        </div>

        {variant !== 'small' && contentComponent}
      </Popover>
    </div>
  )
}
