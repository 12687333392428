import React, { Fragment } from 'react'

/**
 * Convert first letter to uppercase
 */
export const uppercaseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Convert text to camel case
 */
export const toCamelCase = (str: string) => {
  return str
    .split(/\s+/g)
    .map((word) => uppercaseFirstLetter(word))
    .join('')
}

/**
 * Convert newline to html line break
 */
export const nl2br = (str: string | null) => {
  if (!str) {
    return null
  }

  return str.split('\n').map((item, key) => (
    // eslint-disable-next-line
    <Fragment key={key}>
      {item}
      <br />
    </Fragment>
  ))
}

/**
 * Sets characted at specific index to the character given.
 * @param moduleName Name of current module
 * @param categories Ordered list of module category names which specifies the path to current page
 */
export const preparePageTitle = (
  moduleName: string,
  categories: string | (string | null)[] | null = null
) => {
  const hostname = window?.location?.hostname
  const domain = hostname || 'ambitas.org'

  if (!moduleName) {
    return domain
  }

  const moduleNameLowerCase = toCamelCase(moduleName)

  let categoriesArray = Array.isArray(categories) ? categories : [categories]
  categoriesArray = categoriesArray ? categoriesArray.filter((c) => !!c) : []
  const categoriesString =
    categoriesArray.length > 0 ? ` [${categoriesArray.filter((c) => !!c).join(' - ')}]` : ''

  return `${moduleNameLowerCase}.app${categoriesString} | ${domain}`
}

/**
 * Sets characted at specific index to the character given.
 * @param str Source string.
 * @param index Index of the character to be replaced in the source string.
 * @param chr New character / string which will be placed at the given index.
 */
export const setCharAt = (str: string, index: number, chr: string) => {
  if (index > str.length - 1) return str
  return str.substr(0, index) + chr + str.substr(index + 1)
}

/**
 * Sets first char to uppercase.
 * @param s Source string.
 */
export const capitalize = (s?: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

/**
 * Sets the native value of the input element.
 * @param element Input element ref.
 * @param value New value.
 */
export const setNativeValue = (element: any, value: any) => {
  const prototype = Object.getPrototypeOf(element)
  const prototypeValueSetter = (Object as any).getOwnPropertyDescriptor(prototype, 'value').set
  const valueSetter = (Object as any).getOwnPropertyDescriptor(element, 'value').set

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value)
  } else {
    valueSetter.call(element, value)
  }
}

/**
 * Transforms number to string with insignificant leading zeros.
 * @param desiredLength The final length the string is expected to have, including the decimal separator.
 * @param number The number to be converted.
 * @returns Number with leading zeros.
 */
export const prefixWithZeros = (desiredLength: number, number: number) => {
  return `${Array(desiredLength)
    .fill(0)
    .splice(0, desiredLength - number.toString().length)
    .join()
    .replace(/,/g, '')}${number}`
}

/**
 * Check if proprety is function
 * @param func Property to check
 */
export const isFunction = (func: any) => {
  return typeof func === 'function'
}

/**
 * Run callback if it is callable
 * @param  callback Function to run if it is a function
 * @param  args Argument which we pass to callback function
 */
export const runCallback = (callback: any, ...args: any[]) => {
  return isFunction(callback) ? callback.apply(this, args) : undefined
}

export const camelToSnakeCase = (sourceString: string) =>
  sourceString.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const snakeToCamelCase = (sourceString: string) =>
  sourceString.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''))
