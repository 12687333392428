// core
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

// libraries
import classnames from 'classnames'
import { Link } from 'react-router-dom'

// apollo
import { gql, useApolloClient, useQuery } from '@apollo/client'
import { LayoutQueries } from 'queries/Layout/LayoutQueries'

// data types
import { GetLoggedInUser, GetLoggedInUserVariables } from 'queries/Layout/types/GetLoggedInUser'
import { GetMenu } from 'queries/Layout/types/GetMenu'

// components
import { ModalContainer, ModalContext } from 'components/basic/Modal/Modal'
import { LoadingWrapper } from 'components/complex/LoadingWrapper/LoadingWrapper'

import { MenuIcon } from 'layouts/MenuIcon/MenuIcon'

import { ReactComponent as Logo } from 'assets/images/logo.svg'

import { LoggedInUserContext } from '../index'

// partials
import { Content } from './Content/Content'
import { MainMenu } from './MainMenu/MainMenu'
// import { Notifications } from './Notifications/Notifications'
import { Router } from './Router/Router'
import { SearchContext, useSearch } from './Search/useSearch'
import { UserMenu } from './UserMenu/UserMenu'
import { Footer, Header, SidePanel } from './index'

// styles
import * as css from './Layout.scss'

export type ISidePanelTypes = 'mainMenu' | 'userMenu' | 'notifications'

export const SidePanelContext = createContext<{
  currentlyOpenedSidePanel: ISidePanelTypes | null
  openSidePanel: (menu: ISidePanelTypes | null) => any
}>({
  currentlyOpenedSidePanel: null,
  openSidePanel: () => {
    // do nothing
  },
})

export function Layout() {
  const modalRef = useRef<HTMLDivElement>(null)
  const { loggedInUser } = useContext(LoggedInUserContext)

  const [openedSidePanel, setOpenedSidePanel] = useState<ISidePanelTypes | null>(null)
  const searchContextOptions = useSearch()

  const client = useApolloClient()

  const { loading: userLoading, error } = useQuery<GetLoggedInUser, GetLoggedInUserVariables>(
    LayoutQueries.GET_LOGGED_IN_USER,
    {
      skip: !loggedInUser,
      variables: {
        id: loggedInUser?.id || '',
      },
    }
  )

  if (error) {
    console.error(error)
  }

  useEffect(() => {
    if (loggedInUser) {
      client.writeQuery({
        data: { loggedInUserId: loggedInUser.id },
        query: gql`
          query {
            loggedInUserId
          }
        `,
      })
    }
  }, [loggedInUser])

  const { data } = useQuery<GetMenu>(LayoutQueries.GET_MENU)

  const closeAllMenus = useCallback(() => {
    setOpenedSidePanel(null)
  }, [])

  const handleMainMenuButtonClick = useCallback(() => {
    setOpenedSidePanel((openedSidePanel) => (openedSidePanel === 'mainMenu' ? null : 'mainMenu'))
  }, [openedSidePanel === 'mainMenu'])

  const handleLogoClick = useCallback(() => {
    setOpenedSidePanel(null)
  }, [])

  const sidePanelContextOptions = useMemo(
    () => ({
      currentlyOpenedSidePanel: openedSidePanel,
      openSidePanel: setOpenedSidePanel,
    }),
    []
  )

  const menuIsOpen = openedSidePanel === 'mainMenu'

  return (
    <SidePanelContext.Provider value={sidePanelContextOptions}>
      <ModalContext.Provider value={modalRef.current || null}>
        <ModalContainer ref={modalRef} />

        <SearchContext.Provider value={searchContextOptions}>
          <LoadingWrapper loading={userLoading}>
            <Content>
              <div className={css.headerWrap}>
                <Header />

                <MenuIcon isOpen={menuIsOpen} onClick={handleMainMenuButtonClick} />

                <SidePanel
                  inverted
                  open={openedSidePanel === 'mainMenu'}
                  side="left"
                  width={280}
                  onRequestClose={closeAllMenus}
                >
                  <Link
                    className={classnames(
                      css.mainMenuHeader,
                      openedSidePanel === 'mainMenu' ? css.open : ''
                    )}
                    to="/"
                    onClick={handleLogoClick}
                  >
                    <Logo className={css.logo} />
                  </Link>

                  <MainMenu items={data && data.sysMenu ? data.sysMenu : []} />

                  <Footer />
                </SidePanel>
              </div>

              <div className={css.content}>
                {/**
                 * Use another Suspense to display loader only inside main content
                 * (parent Suspense is in Content compoent)
                 */}
                <React.Suspense fallback={<LoadingWrapper loading />}>
                  <Router routes={data ? data.sysMenu : []} />
                </React.Suspense>
              </div>

              {/* loggedInUser ? (
                <SidePanel
                  open={openedSidePanel === 'notifications'}
                  side="right"
                  width={375}
                  onRequestClose={closeAllMenus}
                >
                  <Notifications loggedInUser={loggedInUser} openSidePanel={setOpenedSidePanel} />
                </SidePanel>
              ) : null */}

              {loggedInUser ? (
                <SidePanel
                  open={openedSidePanel === 'userMenu'}
                  side="right"
                  width={375}
                  onRequestClose={closeAllMenus}
                >
                  <UserMenu loggedInUser={loggedInUser} openSidePanel={setOpenedSidePanel} />
                </SidePanel>
              ) : null}

              {/* <SystemNotifications /> */}
            </Content>
          </LoadingWrapper>
        </SearchContext.Provider>
      </ModalContext.Provider>
    </SidePanelContext.Provider>
  )
}
