// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/HelveticaNeue-Thin.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/HelveticaNeue-ThinItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/fonts/HelveticaNeue-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/fonts/HelveticaNeue-LightItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/fonts/HelveticaNeue.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../assets/fonts/HelveticaNeue-Italic.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:300}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:300;font-style:italic}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:400}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");font-weight:400;font-style:italic}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");font-weight:500}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-weight:500;font-style:italic}.Header_root_VGnP8{width:100%;height:50px;padding:0 25px;display:flex;align-items:center;color:#bfbfbf;position:relative;z-index:100;transition:z-index 0s linear .15s}.Header_root_VGnP8.Header_searchFocused_2GkYQ{z-index:101}.Header_root_VGnP8 .Header_searchWrap_Jt2R3{flex:1;display:flex;align-items:flex-start;justify-content:center;margin-left:30px;height:44px}.Header_root_VGnP8 .Header_rightIcons_PTsSz{display:flex;align-items:center}.Header_root_VGnP8 .Header_rightIcons_PTsSz .Header_notificationButton_3jMF1{margin-right:10px}@media (max-width: 767.98px){.Header_root_VGnP8{padding-right:15px;justify-content:flex-end}.Header_root_VGnP8 .Header_searchWrap_Jt2R3{position:fixed;top:3px;left:60px;right:80px;margin-left:0;z-index:100;transition:left .3s ease,right .3s ease,top .3s ease}.Header_root_VGnP8.Header_searchFocused_2GkYQ{transition:z-index 0s}.Header_root_VGnP8.Header_searchFocused_2GkYQ .Header_searchWrap_Jt2R3{top:8px;left:15px;right:15px}.Header_root_VGnP8 .Header_notificationButton_3jMF1{display:none}}\n", ""]);
// Exports
exports.locals = {
	"root": "Header_root_VGnP8",
	"searchFocused": "Header_searchFocused_2GkYQ",
	"searchWrap": "Header_searchWrap_Jt2R3",
	"rightIcons": "Header_rightIcons_PTsSz",
	"notificationButton": "Header_notificationButton_3jMF1"
};
module.exports = exports;
