import { useContext } from 'react'

import { ModalContext } from './Modal'

export function useModalContainer() {
  const container = useContext(ModalContext)

  const { width, height } = container?.parentElement
    ? container.getBoundingClientRect()
    : { height: Infinity, width: Infinity }

  return { container, height, width }
}
