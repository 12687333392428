// core
import React, { memo, useCallback, useContext, useEffect, useState } from 'react'

// libraries
import classnames from 'classnames'

// apollo
import { useLazyQuery } from '@apollo/client'
import { LayoutQueries } from 'queries/Layout/LayoutQueries'

// data types
import { GetUserMenuData, GetUserMenuDataVariables } from 'queries/Layout/types/GetUserMenuData'

// partials
// import { NotificationButton } from 'layouts/NotificationButton/NotificationButton'
import { Search } from 'layouts/Search/Search'
import { UserAvatar } from 'layouts/UserAvatar/UserAvatar'

import { LoggedInUserContext } from '../../index'
import { SidePanelContext } from '../Layout'

// styles
import * as css from './Header.scss'

export const Header = memo(function Header() {
  const { loggedInUser } = useContext(LoggedInUserContext)
  const { openSidePanel } = useContext(SidePanelContext)
  const [searchFocused, setSearchFocused] = useState<boolean>(false)

  const [getUser, { loading, data }] = useLazyQuery<GetUserMenuData, GetUserMenuDataVariables>(
    LayoutQueries.GET_USER_MENU_DATA
  )

  useEffect(() => {
    if (loggedInUser) {
      getUser({
        variables: { id: loggedInUser.id },
      })
    }
  }, [loggedInUser])

  // const handleNotificationClick = useCallback(() => {
  //   openSidePanel('notifications')
  // }, [])

  const handleProfileClick = useCallback(() => {
    openSidePanel('userMenu')
  }, [])

  const handleSearchFocus = useCallback(() => {
    setSearchFocused(true)
  }, [])

  const handleSearchBlur = useCallback(() => {
    setSearchFocused(false)
  }, [])

  return (
    <header className={classnames(css.root, searchFocused ? css.searchFocused : '')}>
      <div className={css.searchWrap}>
        <Search onBlur={handleSearchBlur} onFocus={handleSearchFocus} />
      </div>

      <div className={css.rightIcons}>
        {/* <NotificationButton className={css.notificationButton} onClick={handleNotificationClick} /> */}

        {/* profile controls wrapper */}
        <UserAvatar
          error={!loading && (!data || !data.sysUser)}
          loading={loading}
          profilePicture={data && data.sysUser && data.sysUser.profilePicture}
          onClick={handleProfileClick}
        />
      </div>
    </header>
  )
})
