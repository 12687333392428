// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/HelveticaNeue-Thin.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/fonts/HelveticaNeue-ThinItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/fonts/HelveticaNeue-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/fonts/HelveticaNeue-LightItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../assets/fonts/HelveticaNeue.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../../assets/fonts/HelveticaNeue-Italic.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:300}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:300;font-style:italic}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:400}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");font-weight:400;font-style:italic}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");font-weight:500}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-weight:500;font-style:italic}.Avatar_root_3TPeu{overflow:hidden;border-radius:50%;display:flex;align-items:center;justify-content:center;background-color:#b4b4b4;color:#ffffff}.Avatar_root_3TPeu.Avatar_pico_3wTMB{width:15px;height:15px;min-width:15px;min-height:15px;font-size:10px;line-height:10px}.Avatar_root_3TPeu.Avatar_nano_2Di1T{width:20px;height:20px;min-width:20px;min-height:20px;font-size:10px;line-height:10px}.Avatar_root_3TPeu.Avatar_micro_2DRgl{width:24px;height:24px;min-width:24px;min-height:24px;font-size:12px;line-height:12px}.Avatar_root_3TPeu.Avatar_mini_3BZPx{width:32px;height:32px;min-width:32px;min-height:32px;font-size:14px;line-height:14px}.Avatar_root_3TPeu.Avatar_small_SFZt5{width:36px;height:36px;min-width:36px;min-height:36px;font-size:16px;line-height:16px}.Avatar_root_3TPeu.Avatar_normal_3h_Es{width:50px;height:50px;min-width:50px;min-height:50px;font-size:22px;line-height:22px}.Avatar_root_3TPeu.Avatar_large_2_MqT{width:60px;height:60px;min-width:60px;min-height:60px;font-size:26px;line-height:26px}.Avatar_root_3TPeu.Avatar_huge_2ObtL{width:70px;height:70px;min-width:70px;min-height:70px;font-size:28px;line-height:28px}\n", ""]);
// Exports
exports.locals = {
	"root": "Avatar_root_3TPeu",
	"pico": "Avatar_pico_3wTMB",
	"nano": "Avatar_nano_2Di1T",
	"micro": "Avatar_micro_2DRgl",
	"mini": "Avatar_mini_3BZPx",
	"small": "Avatar_small_SFZt5",
	"normal": "Avatar_normal_3h_Es",
	"large": "Avatar_large_2_MqT",
	"huge": "Avatar_huge_2ObtL"
};
module.exports = exports;
