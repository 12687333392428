// core
import React from 'react'

// libraries
import classNames from 'classnames'

import { runCallback } from 'utils'

// styles
import * as css from './MenuIcon.scss'

interface IMenuIconProps {
  /**
   * Is opened or not
   */
  isOpen: boolean
  /**
   *
   */
  onClick: () => void
}

export const MenuIcon = ({ isOpen, onClick }: IMenuIconProps) => {
  return (
    <div
      className={classNames(css.root, isOpen && css.isOpen)}
      onClick={() => {
        runCallback(onClick)
      }}
    >
      <span />
      <span />
      <span />
    </div>
  )
}
