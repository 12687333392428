// core
import React, { forwardRef, Ref } from 'react'

import classnames from 'classnames'

// components
import { IDotappWrapperComponentProps } from 'components'

// styles
import * as css from './Paper.scss'

export interface IPaperProps extends IDotappWrapperComponentProps {
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Paper elevation
   */
  elevation?: '1' | '2'
  /**
   * Additional style to apply to root
   */
  style?: {}
}

export const Paper = forwardRef(function Paper(
  { inverted, elevation, children, className, style }: IPaperProps,
  ref: Ref<any>
) {
  const elevationClassName = (elevation ? `elevation${elevation}` : 'elevation1') as
    | 'elevation1'
    | 'elevation2'

  return (
    <div
      ref={ref}
      className={classnames(
        css.root,
        css[elevationClassName],
        inverted ? css.inverted : '',
        className
      )}
      style={style}
    >
      {children}
    </div>
  )
})
