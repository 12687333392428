// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormFieldInfo_root_3xwBo{margin-left:5px;color:#bfbfbf}.FormFieldInfo_root_3xwBo.FormFieldInfo_disabled_1Do7F{color:#e6e6e6}.FormFieldInfo_root_3xwBo.FormFieldInfo_disabled_1Do7F.FormFieldInfo_inverted_3orvi{color:#656565}\n", ""]);
// Exports
exports.locals = {
	"root": "FormFieldInfo_root_3xwBo",
	"disabled": "FormFieldInfo_disabled_1Do7F",
	"inverted": "FormFieldInfo_inverted_3orvi"
};
module.exports = exports;
