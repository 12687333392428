// core
import React, { memo, useMemo } from 'react'

// libraries
import { getClient } from 'api/Api'
import classnames from 'classnames'
import DOMPurify from 'dompurify'
import Handlebars from 'handlebars'

// apollo
import { gql, useQuery } from '@apollo/client'

// styles
import * as css from './Translation.scss'

const HandlebarsIntl = require('handlebars-intl')

HandlebarsIntl.registerWith(Handlebars)

export const languageDebugQuery = gql`
  query LanguageDebugQuery {
    selectedLanguageId @client

    debug: settings(alias: "languages") @client {
      property(alias: "debug") {
        value
      }
    }
  }
`

export const translationFragment = gql`
  fragment TranslationFragment on Translation {
    sysLanguageKey {
      translation
    }
  }
`

export const translationQuery = gql`
  query LanguagesKeyTranslationQuery($key: String!, $languageId: ID!) {
    translation(key: $key, languageId: $languageId) @client {
      id
      translationForSysLanguage(sysLanguageId: $languageId) {
        id
        translation
      }
    }
  }
`

export interface ITranslationProps {
  /**
   * Key to translate
   */
  keyValue: string
  /**
   * Variant og key
   */
  keyVariant?: string
}

const replaceVariables = (translation: string, variables: { [variable: string]: string }) => {
  const template = Handlebars.compile(translation)
  return template(variables, {
    data: {
      intl: {
        formats: {
          date: {
            text: {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            },
          },
        },
        locales: 'sk-SK',
      },
    },
  })
}

export const Translation = memo(function Translation({
  keyValue,
  keyVariant,
  ...passingProps
}: ITranslationProps & { [key: string]: any }) {
  const key = keyValue + (keyVariant ? `.${keyVariant}` : '')

  const { data: languageDebugData, error: languageDebugError } = useQuery(languageDebugQuery)

  if (languageDebugError) {
    console.error(languageDebugError)
  }

  const debug = !!languageDebugData?.debug?.property?.value

  const { data, error } = useQuery(translationQuery, {
    skip: !languageDebugData?.selectedLanguageId,
    variables: {
      key,
      languageId: languageDebugData?.selectedLanguageId,
    },
  })

  if (error) {
    console.error(error)
  }

  let { translation } = data?.translation.translationForSysLanguage || {}

  if (debug && !translation) {
    console.warn(`Missing translation for key "${key}"`)
  }

  if (Object.keys(passingProps).length > 0 && translation) {
    translation = replaceVariables(translation, passingProps)
  }

  // prever XSS attacs
  const translationWithHtml = useMemo(() => DOMPurify.sanitize(translation), [translation])

  return debug ? (
    <span
      dangerouslySetInnerHTML={{ __html: translationWithHtml || key }}
      className={classnames(css.root, translation ? css.exists : css.notExists)}
      title={key}
    />
  ) : (
    translation && <span dangerouslySetInnerHTML={{ __html: translationWithHtml }} />
  )
})

export function getTranslation(keyValue: string, options?: { [key: string]: any }) {
  const { variant, ...keyData } = options || {}
  const key = keyValue + (variant ? `.${variant}` : '')

  let languageId = '1'
  let debug = false
  let translation = null

  try {
    const cacheResultDebug: any =
      getClient().readQuery({
        query: languageDebugQuery,
      }) || {}
    languageId = cacheResultDebug?.selectedLanguageId
    debug = !!cacheResultDebug?.debug?.property?.value
  } catch (e) {
    console.error(e)
  }

  if (languageId) {
    try {
      const data: any = getClient().readFragment({
        fragment: gql`
          fragment languageTranslationFragment on SysLanguageKey {
            id
            translationForSysLanguage(sysLanguageId: $languageId) {
              id
              translation
            }
          }
        `,
        id: `SysLanguageKey:{"languageKey":"${key}"}`,
        variables: {
          languageId,
        },
      })

      translation = data && data.translationForSysLanguage.translation
    } catch (e) {
      console.warn(`Missing translation for key "${key}"`, e)
    }
  }

  if (Object.keys(keyData).length > 0 && translation) {
    translation = replaceVariables(translation, keyData)
  }

  return translation || (debug ? key : '')
}
