// core
import React, { ReactNode } from 'react'

// libraries
import classnames from 'classnames'

// components
import { Icon, IconColor } from 'components/basic/Icon/Icon'
import { Avatar, IAvatarProps } from 'components/complex/Avatar/Avatar'

// styles
import * as css from './AvatarWithTitle.scss'

export interface IAvatarWithTitleProps extends IAvatarProps {
  /**
   * Icon to be rendered
   */
  icon?: string
  /**
   * Color of icon
   */
  iconColor?: IconColor
  /**
   * Heading subtitle
   */
  subtitle?: ReactNode
  /**
   * Heading
   */
  title: ReactNode
  /**
   * Avatar variant (default: 'horizontal')
   */
  variant?: 'horizontal' | 'vertical'
}

export const AvatarWithTitle = ({
  className,
  classes = {},
  icon,
  iconColor,
  subtitle,
  size = 'normal',
  title,
  variant = 'horizontal',
  ...passingProps
}: IAvatarWithTitleProps) => {
  return (
    <div className={classnames(css.root, css[size], css[variant], classes.root, className)}>
      {icon ? (
        <Icon className={classnames(css.icon, classes.icon)} color={iconColor} name={icon} />
      ) : (
        <Avatar {...passingProps} className={classnames(css.avatar, classes.avatar)} size={size} />
      )}

      <div className={classnames(css.title, classes.title)}>
        {title}
        {subtitle ? (
          <div className={classnames(css.subtitle, classes.subtitle)}>{subtitle}</div>
        ) : null}
      </div>
    </div>
  )
}
