// core
import React, { memo } from 'react'

import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'

// styles
import * as css from './Icon.scss'

export type IconColor =
  | 'primary'
  | 'white'
  | 'light'
  | 'neutral'
  | 'success'
  | 'warning'
  | 'danger'
  | 'transparent'

export type IconSize = 'mini' | 'small' | 'medium' | 'large' | 'extraLarge'

export interface IIconProps extends IDotappComponentProps {
  /**
   * Name from Material Icons
   */
  name: string
  /**
   * Icon color
   */
  color?: IconColor
  /**
   * Size of icon
   */
  size?: IconSize
}

export const Icon = memo(function Icon({ className, name, color, size = 'medium' }: IIconProps) {
  return (
    <i
      className={classnames(
        'material-icons',
        css.root,
        color ? css[color] : '',
        css[size],
        className
      )}
    >
      {name}
    </i>
  )
})
