// core
import React, { ReactElement, ReactNode } from 'react'

import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { Button } from 'components/complex/Button/Button'
import { Popup } from 'components/complex/Popup/Popup'
import { ITranslationProps } from 'components/complex/Translation/Translation'

// styles
import * as css from './FormFieldInfo.scss'

export interface IFormFieldInfoProps extends IDotappComponentProps {
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Whether field is disabled
   */
  disabled?: boolean
  /**
   * Popup heading
   */
  heading: ReactElement<ITranslationProps>
  /**
   * Popup content
   */
  content: ReactNode
}

/**
 * Info button with popup which contains addition informations for user about field
 */
export const FormFieldInfo = ({
  inverted,
  disabled,
  heading,
  content,
  className,
}: IFormFieldInfoProps) => {
  return (
    <Popup
      className={classnames(
        css.root,
        {
          [css.inverted]: inverted,
          [css.disabled]: disabled,
        },
        className
      )}
      color="primary"
      content={content}
      heading={heading}
      icon="info_outlined"
      inverted={inverted}
    >
      <Button contained color="transparent" iconLeft="info_outlined" size="small" variant="icon" />
    </Popup>
  )
}
