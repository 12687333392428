/**
 * List
 * -------------------
 * Listing basic information,
 * alternative to the Select component (almost the same functionality,
 * but this one is explicitly expanded -> options are visible all the time).
 */

// core
import React, { cloneElement, isValidElement, ReactElement, useEffect } from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import {
  IListOptionProps,
  ListOption,
  ListOptionValueType as ListOptionValueTypeFromOption,
} from 'components/complex/List/ListOption'
import { NoResultsMessage } from 'components/complex/NoResultsMessage/NoResultsMessage'
import { Translation } from 'components/complex/Translation/Translation'

// styles
import * as css from './List.scss'

export type ListOptionValueType = ListOptionValueTypeFromOption

export type IListOption = ReactElement<IListOptionProps> | IListOptionProps

export interface IListProps extends IDotappComponentProps {
  /**
   * Invert colors
   */
  inverted?: boolean
  /**
   * Height of list item
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Type of effect on hover/focus/active
   */
  hoverEffect?: 'simple' | 'normal'
  /**
   * Array of options to be rendered.
   */
  options: IListOption[]
  /**
   * Selected value will be highlighted, it has to be a value which was assigned to one of the options from options array.
   */
  selectedValue?: string | null
  /**
   * The onClick callback (will not be called when the list option has sub options (children)).
   * NOTE: This callback will be called as onClick callback for the suboptions, too.
   * @param value The value of the clicked list option.
   */
  onClick?(value: ListOptionValueType): void
}

export function List({
  inverted,
  size,
  hoverEffect = 'normal',
  options,
  selectedValue,
  onClick,
  className,
}: IListProps) {
  useEffect(() => {
    if (options) {
      options.forEach((option) => {
        if (!isValidElement(option)) {
          if (option.title === undefined) {
            throw new Error('Missing option title in List component options.')
          }
          if (option.value === undefined) {
            throw new Error('Missing option value in List component options.')
          }
        }
      })
    } else {
      throw new Error('Missing options in List component.')
    }
  }, [options])

  const appendProps = (option: IListOptionProps) => {
    return {
      className: classnames(
        option.className,
        option.onClick || onClick ? css[hoverEffect] : '',
        option.value === selectedValue ? css.selected : '',
        size === 'large' ? css.large : size === 'small' ? css.small : ''
      ),
      onClick: option.onClick || onClick,
    }
  }

  const renderOption = (option: IListOption) => {
    return isValidElement(option) ? (
      cloneElement(option, appendProps(option.props))
    ) : (
      <ListOption
        key={option.value}
        {...option}
        {...appendProps(option)}
        size={size !== 'small' ? 'normal' : 'micro'}
      />
    )
  }

  return (
    <ul className={classnames(css.root, { [css.inverted]: inverted }, className)}>
      {options.length > 0 ? (
        options.map((option) => renderOption(option))
      ) : (
        <NoResultsMessage>
          <Translation keyValue="general.no_items" />
        </NoResultsMessage>
      )}
    </ul>
  )
}
