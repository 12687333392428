// core
import React, {
  createContext,
  FocusEvent,
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

// libraries
import classnames from 'classnames'
import { createPortal } from 'react-dom'

// components
import { IDotappWrapperComponentProps } from 'components'
import { Backdrop } from 'components/basic/Backdrop/Backdrop'
import { useModalContainer } from 'components/basic/Modal/useModalContainer'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './Modal.scss'

export const ModalContext = createContext<HTMLDivElement | null>(null)

export interface IModalProps extends Omit<IModalComponentProps, 'x' | 'y'> {
  /**
   * Whether to use backdrop
   */
  backdrop?: boolean
  /**
   * Callback to run on blur
   */
  onBlur?: () => any
  /**
   * X coordinate on page
   */
  x?: number
  /**
   * Y coordinate on page
   */
  y?: number
}

export interface IModalComponentProps extends IDotappWrapperComponentProps {
  /**
   * Whether to show content
   */
  open: boolean
  /**
   * X coordinate on page
   */
  x: number
  /**
   * Y coordinate on page
   */
  y: number
  /**
   * Width on popover
   */
  width: number
  /**
   * Css z-index value
   */
  zIndex?: number
}

export const ModalComponent = ({
  x = 0,
  y = 0,
  zIndex = 500,
  width,
  open,
  className,
  children,
}: IModalComponentProps & {}) => {
  const rootRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={rootRef}
      className={classnames(
        css.root,
        {
          [css.open]: open,
        },
        className
      )}
      style={{
        left: x,
        // do not overlap screen
        maxHeight: innerHeight - y,
        top: y,
        width,
        zIndex,
      }}
    >
      {children}
    </div>
  )
}

export const ModalContainer = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  return <div ref={ref} className={css.container} />
})

export const Modal = ({
  backdrop = true,
  open: requestedOpen,
  x: requestedX,
  y: requestedY,
  zIndex = 500,
  width: requestedWidth,
  onBlur,
  classes = {},
  ...passingProps
}: IModalProps & {}) => {
  const [open, setOpen] = useState<boolean>(false)
  const {
    container: modalContainer,
    width: containerWidth,
    height: containerHeight,
  } = useModalContainer()

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()

      runCallback(onBlur)
    },
    [onBlur]
  )

  useEffect(() => {
    setOpen(requestedOpen)
  }, [requestedOpen])

  if (!modalContainer) {
    return null
  }

  const width = Math.min(requestedWidth, containerWidth)
  let x = typeof requestedX === 'number' ? requestedX : (containerWidth - width) / 2
  let y = typeof requestedY === 'number' ? requestedY : 100

  // check boudaries
  x = Math.min(Math.max(0, x), containerWidth - width)
  y = Math.min(Math.max(0, y), containerHeight)

  return createPortal(
    <>
      {backdrop ? (
        <Backdrop
          className={classes.backdrop}
          visible={open}
          zIndex={zIndex}
          onClick={handleBlur}
        />
      ) : null}

      <ModalComponent open={open} width={width} x={x} y={y} zIndex={zIndex} {...passingProps} />
    </>,
    modalContainer
  )
}
