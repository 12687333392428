// core
import React, { ReactNode } from 'react'

import classnames from 'classnames'

// styles
import * as css from './FormFieldWrap.scss'

export interface IFormFieldWrapProps {
  /**
   * Whether to render input in one line with order components
   */
  inline?: boolean
  /**
   * Pass className to root
   */
  className?: string
  /**
   * Pass chiilddren to component
   */
  children: ReactNode
}

export const FormFieldWrap = ({ inline, className, children }: IFormFieldWrapProps) => {
  return <div className={classnames(css.root, inline ? css.inline : '', className)}>{children}</div>
}
