// must be imported before App
// eslint-disable-next-line simple-import-sort/sort
import './styles/without_modules/index.scss'

// core
import React, {
  createContext,
  useState,
  useEffect,
  Fragment,
  ComponentType,
  useCallback,
} from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, useHistory, useLocation } from 'react-router-dom'
import { init as initApollo } from 'api/Api'

// bugsnag
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { ApolloProvider } from '@apollo/client'

// utils
import { logout, parsePayload, refreshToken } from 'utils/auth'
import { configure as configureYup } from 'utils/yup'

import App from './App'
import * as serviceWorker from './serviceWorker'

import './styles/variables-export.scss'
// import 'bootstrap/dist/css/bootstrap.css'

const hasBugsnag = !!process.env.REACT_APP_BUGSNAG_API_KEY
let ErrorBoundary: ComponentType = Fragment

if (hasBugsnag) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
    appVersion: process.env.REACT_APP_BUILD_NUMBER || '0',
    plugins: [new BugsnagPluginReact()],
  })
  const bugsnagReactPlugin = Bugsnag.getPlugin('react')
  if (bugsnagReactPlugin) {
    ErrorBoundary = bugsnagReactPlugin.createErrorBoundary(React)
  }
}

export type LoggedInUserType = {
  id: string
  name: string
  surname: string
  emailPrimary: string
  accessGroupId: number
  dismissedWelcomeWidget: boolean
}

export const LoggedInUserContext = createContext<{
  loggedInUser: LoggedInUserType | null | undefined
  setLoggedInUser: (data: LoggedInUserType | null) => void
}>({
  loggedInUser: undefined,
  setLoggedInUser: () => {
    // do nothing
  },
})

initApollo().then((client) => {
  function AppSetup() {
    return (
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <Router basename="/">
            <AppRoot />
          </Router>
        </ApolloProvider>
      </ErrorBoundary>
    )
  }

  function AppRoot() {
    const { pathname, search } = useLocation()
    const history = useHistory()

    const [loggedInUser, setLoggedInUserState] = useState<LoggedInUserType | null | undefined>(
      undefined
    )

    const setLoggedInUser = useCallback(
      (user: LoggedInUserType | null) => {
        setLoggedInUserState(user)

        if (!user) {
          logout(true).then(() => {
            history.push('/login', {
              prevPath: pathname + search,
            })
          })
        }
      },
      [setLoggedInUserState, pathname, search, history, client]
    )

    useEffect(() => {
      // set translations for yup
      configureYup()

      // add custom error callback to apollo client
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      client.onError = () => {
        setLoggedInUser(null)

        return Promise.resolve()
      }

      // check stored auth token
      const token = localStorage.getItem('token')

      if (token) {
        const payload = parsePayload(token)

        // check if token expired
        if (payload && typeof payload === 'object' && payload.exp < Date.now() / 1000) {
          setLoggedInUser(null)
        }
        // try to refresh token
        else {
          refreshToken().then((token) => {
            const payload = parsePayload(token)
            setLoggedInUser(typeof payload === 'object' ? (payload as LoggedInUserType) : null)
          })
        }
      } else {
        // we should not redirect user because maybe we are in set-new-password route or any other public route
        setLoggedInUserState(null)
        logout(true)
      }
    }, [])

    return (
      <LoggedInUserContext.Provider value={{ loggedInUser, setLoggedInUser }}>
        <App />
      </LoggedInUserContext.Provider>
    )
  }

  ReactDOM.render(<AppSetup />, document.getElementById('root'))
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
