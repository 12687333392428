// core
import React, { useCallback, useContext } from 'react'

// components
import { SidePanelContext } from '../Layout'

// partials
import { IItemProps, Item } from './Partials/Item'

export interface IMainMenuItemProps extends Omit<IItemProps, 'onClick'> {}

export function MainMenuItem({ ...passingProps }: IMainMenuItemProps) {
  const { openSidePanel } = useContext(SidePanelContext)

  const handleSidebarClose = useCallback(() => {
    openSidePanel(null)
  }, [])

  return <Item {...passingProps} onClick={handleSidebarClose} />
}
