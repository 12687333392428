// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Content_root_1JjTY{flex:1;width:100%;display:flex;flex-direction:column;align-items:center;overflow-y:auto}\n", ""]);
// Exports
exports.locals = {
	"root": "Content_root_1JjTY"
};
module.exports = exports;
