import { createContext, useMemo, useReducer } from 'react'

type State = {
  /**
   * Whether search is focused
   */
  focused: boolean
  /**
   * Current search value
   */
  value: string | null
  /**
   * Whether global search is enabled
   */
  global: boolean
  /**
   * Whether it is possible to use local search
   */
  localSearchAvailable: boolean
}

export type SearchContextOptions = State & {
  /**
   * Set search value
   */
  setValue: (value: string | null) => any
  /**
   * Set global search
   */
  setGlobal: (global: boolean) => any
  /**
   * Set focus to search field
   */
  setFocus: (focused: boolean) => any
  /**
   * Enable or disable local search
   */
  enableLocalSearch: (enabled: boolean) => any
}

const defaultOptions = {
  enableLocalSearch: () => {
    // do nothing
  },
  focused: false,
  global: true,
  localSearchAvailable: false,
  setFocus: () => {
    // do nothing
  },
  setGlobal: () => {
    // do nothing
  },
  setValue: () => {
    // do nothing
  },
  value: null,
}

export const SearchContext = createContext<SearchContextOptions>(defaultOptions)

function reducer(
  state: State,
  {
    type,
    value,
  }:
    | { type: 'value'; value: string | null }
    | { type: 'global'; value: boolean }
    | { type: 'focus'; value: boolean }
    | { type: 'localSearch'; value: boolean }
) {
  switch (type) {
    case 'value':
      return { ...state, value: value ? value.toString() : null }
    case 'localSearch':
      return {
        ...state,
        focus: value ? state.focused : false,
        global: !value,
        localSearchAvailable: !!value,
        value: value ? state.value : null,
      }
    case 'global':
      return { ...state, global: !state.localSearchAvailable || !!value }
    case 'focus':
      return { ...state, focused: !!value }
    default:
      throw new Error()
  }
}

export function useSearch(): SearchContextOptions {
  const [searchApi, dispatch] = useReducer(reducer, {
    focused: false,
    global: true,
    localSearchAvailable: false,
    value: null,
  })

  const contextOptions = useMemo(
    () => ({
      ...searchApi,
      enableLocalSearch: (enabled: boolean) => dispatch({ type: 'localSearch', value: enabled }),
      setFocus: (focused: boolean) => dispatch({ type: 'focus', value: focused }),
      setGlobal: (global: boolean) => dispatch({ type: 'global', value: global }),
      setValue: (value: string | null) => dispatch({ type: 'value', value }),
    }),
    [searchApi, dispatch]
  )

  return contextOptions
}
