// core
import React, { memo, MouseEvent, useContext, useState } from 'react'

// libraries
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'

// apollo
import { useMutation, useQuery } from '@apollo/client'
import { LayoutMutations } from 'queries/Layout/LayoutMutations'
import { LayoutQueries } from 'queries/Layout/LayoutQueries'

// data types
import { GetUserMenuData, GetUserMenuDataVariables } from 'queries/Layout/types/GetUserMenuData'
import { SetUserLanguage, SetUserLanguageVariables } from 'queries/Layout/types/SetUserLanguage'

// components
import { Loading } from 'components/basic/Loading/Loading'
import { Avatar } from 'components/complex/Avatar/Avatar'
import { AvatarWithTitle } from 'components/complex/AvatarWithTitle/AvatarWithTitle'
import { Button } from 'components/complex/Button/Button'
import { ExpandableList } from 'components/complex/ExpandableList/ExpandableList'
import { Translation } from 'components/complex/Translation/Translation'

// assets
import { ReactComponent as FlagEn } from 'assets/images/flag-en.svg'
import { ReactComponent as FlagSk } from 'assets/images/flag-sk.svg'

import { LoggedInUserContext } from '../../index'
import { ISidePanelTypes } from '../Layout'
// import { NotificationButton } from '../NotificationButton/NotificationButton'
import { SidePanelHeader } from '../SidePanel/SidePanelHeader'

// styles
import * as css from './UserMenu.scss'

export interface IUserMenuProps {
  /**
   * Currently logged in user
   */
  loggedInUser: {
    id: string
  }
  /**
   * Function to open diferent SideMenu
   */
  openSidePanel: (id: ISidePanelTypes | null) => any
}

export const UserMenu = memo(function UserMenu({ loggedInUser, openSidePanel }: IUserMenuProps) {
  const history = useHistory()

  const { setLoggedInUser } = useContext(LoggedInUserContext)

  const [confirmLogout, setConfirmLogout] = useState<boolean>(false)

  // const activePath = '/access-right'
  const { data } = useQuery<GetUserMenuData, GetUserMenuDataVariables>(
    LayoutQueries.GET_USER_MENU_DATA,
    {
      variables: { id: loggedInUser.id },
    }
  )
  const [setUserLanguage, { loading }] = useMutation<SetUserLanguage, SetUserLanguageVariables>(
    LayoutMutations.SET_USER_LANGUAGE
  )

  const handleRequestClose = () => {
    openSidePanel(null)
  }

  // const handleNotificationClick = () => {
  //   openSidePanel('notifications')
  // }

  const handleItemClick = (action: string) => {
    if (action === 'logout') {
      setConfirmLogout(true)
    }
  }

  const handleLanguageChange = (languageId: string) => (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    localStorage.setItem('languageId', languageId)

    setUserLanguage({
      variables: {
        id: loggedInUser.id,
        languageId,
      },
    })
  }

  const handleLogout = () => {
    setLoggedInUser(null)
  }

  const handleManageAccountClick = () => {
    if (data?.sysUser) {
      history.push(`/user/${data.sysUser.id}`)
    }
    openSidePanel(null)
  }

  const handleLogoutCancel = () => {
    setConfirmLogout(false)
  }

  return (
    <div className={classnames(css.root)}>
      <SidePanelHeader
        heading={<Translation keyValue="general.user_menu" />}
        onRequestClose={handleRequestClose}
      >
        {/* <NotificationButton className={css.notificationButton} onClick={handleNotificationClick} /> */}
      </SidePanelHeader>

      {data?.sysUser ? (
        <div className={css.userInfo}>
          <AvatarWithTitle
            icon={!data.sysUser.profilePicture ? 'user' : undefined}
            subtitle={data.sysUser.emailPrimary}
            title={data.sysUser.fullName}
            url={data.sysUser.profilePicture || undefined}
          />
        </div>
      ) : null}

      <ExpandableList
        className={css.menu}
        options={[
          {
            onClick: handleManageAccountClick,
            title: <Translation keyValue="general.manage_account" />,
            value: 'profile',
          },
          {
            contentBellow: data && (
              <div className={css.flags}>
                {data.sysLanguages.map((language) => {
                  const isSelected = data.selectedLanguageId === language.id

                  return (
                    <a
                      key={language.id}
                      className={isSelected ? css.active : ''}
                      href="#"
                      onClick={handleLanguageChange(language.id)}
                    >
                      {loading && isSelected ? (
                        <Avatar size="small">
                          <Loading />
                        </Avatar>
                      ) : language.codeShort === 'EN' ? (
                        <FlagEn />
                      ) : language.codeShort === 'SK' ? (
                        <FlagSk />
                      ) : (
                        <Avatar size="small">{language.codeShort}</Avatar>
                      )}
                    </a>
                  )
                })}
              </div>
            ),
            title: <Translation keyValue="general.language_settings" />,
            value: 'language',
          },
          {
            className: css.logout,
            iconRight: 'input',
            title: <Translation keyValue="general.actions.logout" />,
            value: 'logout',
          },
        ]}
        onClick={handleItemClick}
      />

      {confirmLogout ? (
        <div className={css.confirmLogout}>
          <div>
            <Translation keyValue="general.confirm_logout" />
          </div>

          <Button
            color="success"
            iconLeft="check"
            size="small"
            variant="icon"
            onClick={handleLogout}
          />

          <Button
            contained
            className={css.logoutButton}
            color="danger"
            iconLeft="close"
            size="small"
            variant="icon"
            onClick={handleLogoutCancel}
          />
        </div>
      ) : null}
    </div>
  )
})
