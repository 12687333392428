// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/HelveticaNeue-Thin.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/fonts/HelveticaNeue-ThinItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/fonts/HelveticaNeue-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/fonts/HelveticaNeue-LightItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../assets/fonts/HelveticaNeue.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../../assets/fonts/HelveticaNeue-Italic.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:300}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:300;font-style:italic}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:400}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");font-weight:400;font-style:italic}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");font-weight:500}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-weight:500;font-style:italic}.Icon_root_3cCJ5{display:block;width:1em;line-height:1em;overflow:hidden}.Icon_root_3cCJ5.Icon_primary_19C__{color:#0091ff}.Icon_root_3cCJ5.Icon_white_2SUNr{color:#ffffff}.Icon_root_3cCJ5.Icon_light_2CuL6{color:#b4b4b4}.Icon_root_3cCJ5.Icon_neutral_29u7t{color:#a6a6a6}.Icon_root_3cCJ5.Icon_success_384wT{color:#28ca42}.Icon_root_3cCJ5.Icon_warning_201bf{color:#fc0}.Icon_root_3cCJ5.Icon_danger_3BQvN{color:#ff6059}.Icon_root_3cCJ5.Icon_mini_2w-G3{font-size:16px}.Icon_root_3cCJ5.Icon_small_3tR6v{font-size:20px}.Icon_root_3cCJ5.Icon_medium_3vEt5{font-size:24px}.Icon_root_3cCJ5.Icon_large_1a9Rl{font-size:28px}.Icon_root_3cCJ5.Icon_extraLarge_1-kUg{font-size:38px}\n", ""]);
// Exports
exports.locals = {
	"root": "Icon_root_3cCJ5",
	"primary": "Icon_primary_19C__",
	"white": "Icon_white_2SUNr",
	"light": "Icon_light_2CuL6",
	"neutral": "Icon_neutral_29u7t",
	"success": "Icon_success_384wT",
	"warning": "Icon_warning_201bf",
	"danger": "Icon_danger_3BQvN",
	"mini": "Icon_mini_2w-G3",
	"small": "Icon_small_3tR6v",
	"medium": "Icon_medium_3vEt5",
	"large": "Icon_large_1a9Rl",
	"extraLarge": "Icon_extraLarge_1-kUg"
};
module.exports = exports;
