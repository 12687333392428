// import { persistCache } from 'apollo-cache-persist'

import { ApolloClient, from } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/link-persisted-queries'

// utils
import { setNewToken } from 'utils/auth'

import { cache } from './Cache'

let client: ApolloClient<any>

export const init = () =>
  // persistCache({
  //   cache,
  //   storage: localStorage,
  // })
  Promise.resolve().then(() => {
    const setAuthorizationLink = setContext(() => {
      const token = localStorage.getItem('token')

      return {
        headers: { Authorization: token ? `Bearer ${token}` : '' },
      }
    })

    const errorLink = onError(({ graphQLErrors }) => {
      if (graphQLErrors?.find((error) => error.extensions?.code === 'UNAUTHENTICATED')) {
        // @ts-ignore
        if (typeof client.onError === 'function') {
          // @ts-ignore
          client.onError() // invoke callback in index.js which will redirect user to login
        }
      }
    })

    const httpLink = new BatchHttpLink({
      fetch: (uri: string, options: any) =>
        fetch(uri, options).then((res) => {
          // check if there is new token in extensions
          const newToken = res.headers.get('newToken')

          if (newToken) {
            setNewToken(newToken)
          }

          return res
        }),
      uri: process.env.REACT_APP_API_URL,
    })

    client = new ApolloClient({
      cache,
      link: from([setAuthorizationLink, errorLink, createPersistedQueryLink(), httpLink]),
      name: 'dotapp-web',
    })

    return client
  })

export const getClient = () => client
