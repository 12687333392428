// core
import React, { ReactElement } from 'react'

import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { ITranslationProps, Translation } from 'components/complex/Translation/Translation'

// styles
import * as css from './NoResultsMessage.scss'

export interface INoResultsMessageProps extends IDotappComponentProps {
  /**
   * Pass children to component
   */
  children?: ReactElement<ITranslationProps>
}

export function NoResultsMessage({ children, className }: INoResultsMessageProps) {
  return (
    <p className={classnames(`errorText`, css.root, className)}>
      {children || <Translation keyValue="general.no_results" />}
    </p>
  )
}
