import { gql } from '@apollo/client'

import { LayoutFragments } from './LayoutFragments'

export const LayoutQueries: any = {}
LayoutQueries.GET_LOGGED_IN_USER = gql`
  query GetLoggedInUser($id: ID!) {
    sysUser(id: $id) {
      id
      languageId
      sysAccessGroup {
        id
        enabledProperties {
          id
          token
          sysModule {
            id
            token
          }
        }
      }
    }
  }
`

LayoutQueries.GET_MENU = gql`
  query GetMenu {
    sysMenu {
      id
      ...MenuItemFragment
      subMenu {
        id
        ...MenuItemFragment
        subMenu {
          id
          ...MenuItemFragment
        }
      }
    }
  }
  ${LayoutFragments.MENU_ITEM}
`

LayoutQueries.GET_SETTINGS = gql`
  query GetSettings {
    sysSettingsOptions {
      id
      alias
      sysSettingsOptionItems {
        id
        alias
        value
      }
    }
  }
`

LayoutQueries.GET_TRANSLATIONS = gql`
  query GetTranslations($languageId: ID!) {
    selectedLanguageId @client @export(as: "languageId")

    sysLanguageKeys(first: 9999, orderBy: null, orderByDirection: ASC, search: null, tags: null)
      @connection(
        key: "sysLanguageKeys"
        filter: ["orderBy", "orderByDirection", "search", "tags"]
      ) {
      edges {
        node {
          id
          languageKey
          translationForSysLanguage(sysLanguageId: $languageId) {
            id
            translation
          }
        }
        cursor
      }
      pageInfo {
        endCursor
      }
      totalCount
    }
  }
`

LayoutQueries.GET_USER_MENU_DATA = gql`
  query GetUserMenuData($id: ID!) {
    selectedLanguageId @client

    sysUser(id: $id) {
      id
      languageId
      ...UserInfoHeaderFragment
    }

    sysLanguages {
      id
      codeShort
    }
  }
  ${LayoutFragments.USER}
`
