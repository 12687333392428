// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/HelveticaNeue-Thin.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/HelveticaNeue-ThinItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/HelveticaNeue-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/fonts/HelveticaNeue-LightItalic.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/fonts/HelveticaNeue.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/fonts/HelveticaNeue-Italic.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:300}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:300;font-style:italic}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:400}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");font-weight:400;font-style:italic}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");font-weight:500}@font-face{font-family:'Helvetica Neue';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-weight:500;font-style:italic}\n", ""]);
// Exports
exports.locals = {
	"primary": "#0091ff",
	"secondary": "#6c757d",
	"success": "#28ca42",
	"info": "#17a2b8",
	"warning": "#fc0",
	"danger": "#ff6059",
	"light": "#b4b4b4",
	"dark": "#343a40",
	"linkSelected": "#bfbfbf",
	"animationSpeed": ".3s",
	"bgDefault": "#fff",
	"bgAccent": "#252525",
	"bgConceal": "#e6e6e6"
};
module.exports = exports;
