// core
import React, {
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

// components
import { ExpandableBox } from 'components/basic/ExpandableBox/ExpandableBox'
import { IListProps, List, ListOptionValueType } from 'components/complex/List/List'
import { IListOptionProps } from 'components/complex/List/ListOption'

// utils
import { runCallback } from 'utils/functions'

export type IExpandableListOptionProps = Omit<IListOptionProps, 'iconRight'> & {
  /**
   * Set right icon.
   * iconRight = false - do not display icon
   * iconRight = string - display icon or arrow_down if there are some children
   */
  iconRight?: IListOptionProps['iconRight'] | false
}

export type IExpandableListOption = ReactElement<IListOptionProps> | IExpandableListOptionProps

export interface IExpandableListProps extends Omit<IListProps, 'options'> {
  /**
   * Array of child options to be rendered.
   */
  options: IExpandableListOption[]
  /**
   * Callback to render expanded item coontent
   */
  renderOptionContent?: (option: IListOptionProps, expanded: boolean) => ReactNode
}

export function ExpandableList({
  inverted,
  options,
  onClick,
  renderOptionContent,
  ...passingProps
}: IExpandableListProps) {
  const [expandedValue, setExpandedValue] = useState<string | null>(null)

  const handleClick = useCallback(
    (value: ListOptionValueType) => {
      setExpandedValue((expandedValue) => (value === expandedValue ? null : value))

      runCallback(onClick, value)
    },
    [onClick, setExpandedValue]
  )

  const appendProps = (parentProps: IExpandableListOptionProps) => {
    const { contentBellow, iconRight, ...passingOptionProps } = parentProps

    const expanded = passingOptionProps.value === expandedValue

    return {
      contentBellow: (
        <ExpandableBox expanded={expanded}>
          {contentBellow ||
            (renderOptionContent ? renderOptionContent(passingOptionProps, expanded) : null)}
        </ExpandableBox>
      ),
      iconRight:
        iconRight === false
          ? null
          : contentBellow || renderOptionContent
          ? expanded
            ? 'remove'
            : 'keyboard_arrow_down'
          : iconRight
          ? iconRight.toString()
          : null,
    }
  }

  const renderOption = (option: IExpandableListOption) => {
    if (isValidElement(option)) {
      return cloneElement(option, appendProps(option.props))
    }
    return {
      ...option,
      ...appendProps(option),
    }
  }

  const optionsWithAdditionalProps = useMemo(() => options.map((option) => renderOption(option)), [
    options,
    expandedValue,
  ])

  return (
    <List
      inverted={inverted}
      options={optionsWithAdditionalProps}
      onClick={handleClick}
      {...passingProps}
    />
  )
}
