// libraries
import moment from 'moment'
import validator from 'validator'

import * as yup from 'yup'

import { getTranslation } from 'components/complex/Translation/Translation'

export const configure = () => {
  yup.setLocale({
    mixed: {
      required: () => getTranslation('general.validationErrors.required') || 'Required',
    },
    number: {
      max: ({ max }) =>
        getTranslation('general.validationErrors.max', { value: max }) || `Min ${max}`,

      min: ({ min }) =>
        getTranslation('general.validationErrors.min', { value: min }) || `Min ${min}`,
    },
    string: {
      max: ({ max }) =>
        getTranslation('general.validationErrors.maxLength', { value: max }) || `Max length ${max}`,

      min: ({ min }) =>
        getTranslation('general.validationErrors.minLength', { value: min }) || `Min length ${min}`,
    },
  })

  yup.addMethod<yup.StringSchema>(yup.string, 'json', function (message: string) {
    return this.test(
      'is-valid-json',
      message || getTranslation('general.validationErrors.invalidJson') || 'Invalid JSON',
      (value: string) => {
        try {
          JSON.parse(value)
        } catch {
          return false
        }
        return true
      }
    )
  })

  yup.addMethod<yup.StringSchema>(yup.string, 'email', function (message: string) {
    return this.test(
      'is-valid-email',
      message || getTranslation('general.validationErrors.email') || 'Invalid e-mail',
      (value: string) => validator.isEmail(value) // same validator as api use
    )
  })

  yup.addMethod<yup.StringSchema>(yup.string, 'isUrl', function (message: string) {
    return this.test(
      'is-valid-url',
      message || getTranslation('general.validationErrors.url') || 'Invalid URL',
      (value: string) => {
        const url = new RegExp(
          /^mailto:([^\\?]+)|^(tel:([^\\?]+))|^((https?):\/\/)?(www.)?[a-zA-Z0-9-.]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_@#&=?.]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
        )
        return !!value?.match(url)
      }
    )
  })

  yup.addMethod<yup.DateSchema>(yup.date, 'format', function parseDateFromFormats(
    formats,
    parseStrict
  ) {
    return this.transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value
      }

      const date = moment(originalValue, formats, parseStrict)

      return date.isValid() ? date.toDate() : new Date()
    })
  })
}
