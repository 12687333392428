// core
import React, { useEffect, useRef } from 'react'

import classnames from 'classnames'

// components
import { IDotappWrapperComponentProps } from 'components'
import { ExpandableBox } from 'components/basic/ExpandableBox/ExpandableBox'
import { Modal } from 'components/basic/Modal/Modal'
import { Paper } from 'components/basic/Paper/Paper'

// styles
import * as css from './Popover.scss'

export interface IPopoverProps extends IPopoverComponentProps {
  /**
   * Whether to use backdrop
   */
  backdrop?: boolean
  /**
   * Callback to run on blur
   */
  onBlur?: () => any
  /**
   * Css z-index value
   */
  zIndex?: number
}

export interface IPopoverComponentProps extends IDotappWrapperComponentProps {
  /**
   * Invert colors
   */
  inverted?: boolean
  /**
   * Whether to show content
   */
  open: boolean
  /**
   * From which side to open
   */
  side?: 'left' | 'right'
  /**
   * Width of popover
   */
  width?: number
}

function PopoverComponent({
  inverted,
  open,
  side = 'left',
  width,
  className,
  children,
  classes = {},
}: IPopoverProps) {
  const rootRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current.style.width = open ? '100%' : '0px'
    }
  }, [open, children])

  return (
    <div
      ref={rootRef}
      className={classnames(
        css.root,
        {
          [css.open]: open,
        },
        css[side],
        className
      )}
    >
      <Paper className={classnames(css.paper, classes.paper)} elevation="2" inverted={inverted}>
        <ExpandableBox fade expanded={open}>
          <div className={classnames(css.content, classes.content)} style={{ width }}>
            {children}
          </div>
        </ExpandableBox>
      </Paper>
    </div>
  )
}

export function Popover({
  open,
  backdrop,
  side = 'left',
  onBlur,
  zIndex = 700,
  className,
  classes = {},
  width: requestedWidth,
  ...passingProps
}: IPopoverProps) {
  const popoverPositionRef = useRef<HTMLDivElement>(null)

  const popoverWidth = requestedWidth || 400
  const { x, y, width } = popoverPositionRef.current
    ? popoverPositionRef.current.getBoundingClientRect()
    : { width: popoverWidth, x: undefined, y: undefined }

  return (
    <div ref={popoverPositionRef} className={classnames(css.refDiv, css[side], className)}>
      <Modal
        backdrop={backdrop}
        className={classes.modal}
        open={open}
        width={popoverWidth}
        x={side === 'left' ? x : x && x + width - popoverWidth}
        y={y}
        zIndex={zIndex}
        onBlur={onBlur}
      >
        <PopoverComponent
          classes={classes}
          className={classes.popover}
          open={open}
          side={side}
          width={popoverWidth}
          {...passingProps}
        />
      </Modal>
    </div>
  )
}
