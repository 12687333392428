import { gql } from '@apollo/client'

export const LayoutMutations: any = {}
LayoutMutations.SET_USER_LANGUAGE = gql`
  mutation SetUserLanguage($id: ID!, $languageId: ID!) {
    updateSysUser(id: $id, data: { languageId: $languageId }) {
      id
      languageId
    }
  }
`
