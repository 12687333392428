import { gql } from '@apollo/client'

export const LayoutFragments: any = {}
LayoutFragments.MENU_ITEM = gql`
  fragment MenuItemFragment on SysMenu {
    id
    icon
    menuTitle
    url
  }
`

LayoutFragments.USER = gql`
  fragment UserInfoHeaderFragment on SysUser {
    id
    fullName
    emailPrimary
    profilePicture
  }
`
