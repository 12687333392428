import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

export const cache = new InMemoryCache({
  typePolicies: {
    Gift: {
      fields: {
        vouchers: relayStylePagination(['orderBy', 'orderByDirection', 'search']),
      },
    },

    Query: {
      fields: {
        banners: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'activeSince',
          'activeUntil',
          'showTitleInApp',
        ]),

        beaconZones: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'status',
          'companyId',
          'defaultLanguage',
          'estimoteDeviceIdentifier',
        ]),

        gifts: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'activeSince',
          'activeUntil',
          'type',
        ]),

        iotApps: relayStylePagination(['orderBy', 'orderByDirection']),

        loggedInUser: (existingData, { toReference, readField }) =>
          existingData ||
          toReference({
            __typename: 'SysUser',
            id: readField('loggedInUserId', toReference({ __typename: 'Query' })),
          }),

        loggedInUserId: (existingData) => existingData || null,

        newsArticles: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'publishedAt',
          'pushNotification',
        ]),

        notifications: relayStylePagination(['targetSysUserId', 'isSeen']),

        ppePosts: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'type',
          'ownerId',
          'state',
          'expirationDate',
          'hasImage',
          'isFavorited',
        ]),

        selectedLanguageId: (existingData, { toReference, readField }) =>
          existingData ||
          (readField(
            'languageId',
            toReference({
              __typename: 'SysUser',
              id: readField('loggedInUserId', toReference({ __typename: 'Query' })),
            })
          ) as any) ||
          localStorage.getItem('languageId') ||
          '1',

        settings: (existingData, { args, toReference }) =>
          existingData ||
          toReference({ __typename: 'SysSettingsOption', alias: args ? args.alias : '' }),

        sysLanguageKeys: relayStylePagination(['orderBy', 'orderByDirection', 'search', 'tags']),

        sysUserVerifications: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'status',
          'documentType',
        ]),

        sysUsers: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'statuses',
          'sysAccessGroups',
          'supervisor',
          'bozp',
          'gdpr',
          'createdAt',
          'lastLoginAt',
        ]),

        translation: (existingData, { args, toReference }) =>
          existingData ||
          toReference({ __typename: 'SysLanguageKey', languageKey: args ? args.key : '' }),
      },
    },
    Subscription: {
      fields: {
        messageRead: {
          merge: (_, incoming) => {
            return incoming
          },
        },
      },
    },
    SysLanguageKey: {
      keyFields: ['languageKey'],
    },
    SysLanguageKeySysLanguage: {
      keyFields: false,
    },
    SysSettingsOption: {
      fields: {
        property: (existingData, { args, toReference }) =>
          existingData ||
          toReference({ __typename: 'SysSettingsOptionItem', alias: args ? args.alias : '' }),
      },
      keyFields: ['alias'],
    },
    SysSettingsOptionItem: {
      keyFields: ['alias'],
    },
  },
})
