import { gql } from '@apollo/client'

export const AuthMutations: any = {}
AuthMutations.CONTACT_SUPPORT = gql`
  mutation contactSupport(
    $userId: ID
    $userFullName: String
    $userEmail: String
    $title: String!
    $description: String!
  ) {
    contactSupport(
      userId: $userId
      userFullName: $userFullName
      userEmail: $userEmail
      title: $title
      description: $description
    )
  }
`

AuthMutations.LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`

AuthMutations.REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken
  }
`

AuthMutations.RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`

AuthMutations.SET_NEW_PASSWORD = gql`
  mutation setNewPassword($id: ID!, $secret: String!, $newPassword: String!) {
    setNewPassword(id: $id, secret: $secret, newPassword: $newPassword)
  }
`
