// core
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'

// libraries
import classnames from 'classnames'

// formik
import { Formik } from 'formik'

// components
import { Backdrop } from 'components/basic/Backdrop/Backdrop'
import { Button } from 'components/complex/Button/Button'
import { SearchInput } from 'components/complex/SearchInput/SearchInput'
import { getTranslation } from 'components/complex/Translation/Translation'

// utils
import { runCallback } from 'utils/functions'

// stores
import { LayoutStore } from '../LayoutStore'

import { SearchContext } from './useSearch'

// styles
import * as css from './Search.scss'

// translate

export interface ISearchProps {
  /**
   * Callback to fun on focus
   */
  onFocus?: () => any
  /**
   * Callback to fun on blur
   */
  onBlur?: () => any
}

export const Search = memo(function Search({ onFocus, onBlur }: ISearchProps) {
  const timeout = useRef<any>()

  const [searchValue, setSearchValue] = useState<string>('')
  const searchApi = useContext(SearchContext)

  // clear value on reset
  useEffect(() => {
    if (!searchApi.value) {
      setSearchValue('')
    }
  }, [!!searchApi.value])

  const handleFocus = useCallback(() => {
    searchApi.setFocus(true)
    runCallback(onFocus)
  }, [searchApi, onFocus])

  const handleBlur = useCallback(() => {
    searchApi.setFocus(false)
    runCallback(onBlur)
  }, [searchApi, onBlur])

  const handleClose = useCallback(() => {
    setSearchValue('')
    searchApi.setValue(null)
    handleBlur()
  }, [setSearchValue, searchApi, handleBlur])

  const handleSearchTypeToggleClick = useCallback(() => {
    searchApi.setGlobal(!searchApi.global)
  }, [searchApi])

  const handleSearch = useCallback(
    (v: string) => {
      setSearchValue(v)

      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      timeout.current = setTimeout(() => {
        const value = v.trim()

        searchApi.setValue(value && value !== '' ? value : null)

        LayoutStore.getSearchSuggestions()
      }, 300)
    },
    [setSearchValue, searchApi, timeout]
  )

  // temporary hide global search
  return searchApi.global ? null : (
    <>
      <Backdrop className={css.backdrop} visible={searchApi.focused} onClick={handleBlur} />

      <div
        className={classnames(
          css.root,
          searchApi.focused ? css.focused : '',
          searchApi.focused || searchApi.value ? css.hasValue : ''
        )}
      >
        <div className={css.inputWrap}>
          <Formik
            initialValues={{}}
            onSubmit={() => {
              // do nothing
            }}
          >
            <SearchInput
              name="search"
              placeholder={
                searchApi.global
                  ? getTranslation('general.search_global')
                  : getTranslation('general.search_local')
              }
              rightRender={
                /* temporary hide global search */
                false && searchApi.focused && searchApi.localSearchAvailable ? (
                  <Button
                    className={css.searchTypeToggle}
                    color={searchApi.global ? 'primary' : 'neutral'}
                    size="mini"
                    variant="text"
                    onClick={handleSearchTypeToggleClick}
                  >
                    Global
                  </Button>
                ) : undefined
              }
              value={searchValue}
              onBlur={handleBlur}
              onChange={handleSearch}
              onFocus={handleFocus}
            />
          </Formik>

          {searchApi.focused || searchApi.value ? (
            <Button
              contained
              className={css.closeButton}
              color="transparent"
              iconLeft="close"
              size="small"
              variant="icon"
              onClick={handleClose}
            />
          ) : null}
        </div>

        {searchApi.global ? (
          <div className={css.resultsWrap}>
            {searchApi.value ? (
              <ul>
                <li>
                  <a href="#">
                    <div>
                      <span>
                        <strong>Web</strong>
                        support
                      </span>
                      <span>in Invoices</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div>
                      <span>
                        <strong>Web</strong>
                        design
                      </span>
                      <span>in Project Documentation</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div>
                      <span>
                        <strong>Web</strong>
                        Expo
                      </span>
                      <span>in Events</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div>
                      <span>
                        <strong>Web</strong>
                        site Dataweb
                      </span>
                      <span>in Projects</span>
                    </div>
                  </a>
                </li>
              </ul>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
})
