// core
import React, { ReactElement, ReactNode } from 'react'

// import classnames from 'classnames'
// components
import { Button } from 'components/complex/Button/Button'
import { ITranslationProps } from 'components/complex/Translation/Translation'

// styles
import * as css from './SidePanel.scss'

export interface ISidePanelHeaderProps {
  /**
   * Heading
   */
  heading: ReactElement<ITranslationProps>
  /**
   * Callback to run when user request panel close
   */
  onRequestClose: () => any
  /**
   * Pass children to right side
   */
  children: ReactNode
}

export function SidePanelHeader({ heading, onRequestClose, children }: ISidePanelHeaderProps) {
  return (
    <div className={css.sidePanelHeader}>
      <h4>{heading}</h4>

      <Button
        className={css.closeButton}
        color="neutral"
        iconLeft="chevron_right"
        size="small"
        variant="icon"
        onClick={onRequestClose}
      />

      {children}
    </div>
  )
}
