// core
import React, { forwardRef, ReactNode } from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { Icon } from 'components/basic/Icon/Icon'
import { Avatar } from 'components/complex/Avatar/Avatar'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './List.scss'

/**
 * Allowed datatypes as list option value.
 */
export type ListOptionValueType = string

export interface IListOptionProps extends IDotappComponentProps {
  /**
   * The title of the list option, should be used as the major information (containing the most important information).
   */
  title: ReactNode
  /**
   * The value of the list option, will be passed in the onClick callback.
   */
  value: ListOptionValueType
  /**
   * Left icon name.
   */
  iconLeft?: string | null
  /**
   * Right icon name.
   */
  iconRight?: string | null
  /**
   * Image source (url).
   */
  image?: string
  /**
   * The subtitle of the option, should contain less important information.
   */
  subtitle?: string
  /**
   * Custom element which will be rendered on the left side of the item.
   */
  startRender?: ReactNode
  /**
   * Custom element which will be rendered on the right side of the item.
   */
  endRender?: ReactNode
  /**
   * Custom element which will be rendered bellow item
   */
  contentBellow?: ReactNode | null
  /**
   * Callback to run on option click
   */
  onClick?: (value: ListOptionValueType) => any
  /**
   * Option size
   */
  size?: 'micro' | 'mini' | 'small' | 'normal'
}

export const ListOption = forwardRef(function ListOption(
  {
    startRender,
    image,
    iconLeft,
    title,
    subtitle,
    iconRight,
    endRender,
    value,
    contentBellow,
    onClick,
    className,
    size,
  }: IListOptionProps,
  ref: any
) {
  const content = (
    <div className={css.item}>
      {startRender}

      {image || iconLeft ? (
        <Avatar className={css.avatar} icon={iconLeft} size={size} url={image} />
      ) : null}

      <div className={classnames(css.content, subtitle ? css.hasSubtitle : '')}>
        <div className={css.title}>{title}</div>
        {subtitle ? <span className={css.subtitle}>{subtitle}</span> : null}
      </div>

      {iconRight ? <Icon className={css.iconRight} name={iconRight} /> : null}

      {endRender}
    </div>
  )

  return (
    <li ref={ref} className={className}>
      {onClick ? (
        <a
          className={css.itemWrap}
          href="#"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            event.currentTarget.blur()

            runCallback(onClick, value)
          }}
        >
          {content}
        </a>
      ) : (
        <div className={css.itemWrap}>{content}</div>
      )}

      {contentBellow ? <div className={css.contentBellow}>{contentBellow}</div> : null}
    </li>
  )
})
