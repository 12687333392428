// core
import React, { ReactNode } from 'react'

// libraries
import classnames from 'classnames'

// components
import { LoadingWrapper } from 'components/complex/LoadingWrapper/LoadingWrapper'

// styles
import * as css from './Content.scss'

export interface IContentProps {
  /**
   * Pass children to component
   * @type {ReactNode}
   */
  children: ReactNode
  /**
   * Add custom classname
   */
  className?: string
}

export const Content = ({ className, children }: IContentProps) => {
  return (
    <React.Suspense fallback={<LoadingWrapper loading />}>
      <div className={classnames(css.root, className)}>{children}</div>
    </React.Suspense>
  )
}
