// core
import React, { memo, MouseEvent, useCallback } from 'react'

// components
import { Loading } from 'components/basic/Loading/Loading'
import { Avatar } from 'components/complex/Avatar/Avatar'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './UserAvatar.scss'

export interface IUserAvatarProps {
  /**
   * Whether show loading indicator
   */
  loading?: boolean
  /**
   * Whether there is error in loading data
   */
  error?: boolean
  /**
   * User picture url to show
   */
  profilePicture?: string | null
  /**
   * Callback on click
   */
  onClick?: () => any
}

export const UserAvatar = memo(function UserAvatar({
  loading,
  error,
  profilePicture,
  onClick,
}: IUserAvatarProps) {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      runCallback(onClick)
    },
    [onClick]
  )

  return (
    <a className={css.root} href="#" onClick={handleClick}>
      {loading ? (
        <Avatar size="small">
          <Loading />
        </Avatar>
      ) : !error ? (
        <Avatar
          icon={!profilePicture ? 'user' : undefined}
          size="small"
          url={profilePicture || undefined}
        />
      ) : (
        <Avatar icon="warning" size="small" />
      )}
    </a>
  )
})
