// core
import React, { cloneElement, ReactElement } from 'react'

import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'

// styles
import * as css from './FormFieldSideRender.scss'

export interface IFormFieldSideRenderProps extends IDotappComponentProps {
  /**
   * Whether colors should be inverted
   */
  inverted?: boolean
  /**
   * Pass children to component
   */
  children?: ReactElement<any>
}

/**
 * Wrapper for side render for field component. Usually you will render another field inside
 */
export const FormFieldSideRender = ({ className, children }: IFormFieldSideRenderProps) => {
  const component = children ? React.Children.only(children) : null
  const classNameString = classnames(
    component && component.props ? component.props.className : '',
    css.root,
    className
  )

  return <>{component ? cloneElement(component, { className: classNameString }) : null}</>
}
