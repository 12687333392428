import * as React from 'react'

import { IRoutes } from './index'

const Login = React.lazy(() => import('pages/Auth/Login'))
const SetNewPassword = React.lazy(() => import('pages/Auth/SetNewPassword'))

const routes: IRoutes[] = [
  {
    component: Login,
    path: '/login',
  },
  {
    component: SetNewPassword,
    path: '/set-new-password',
  },
]

export const AuthRoutes = routes
