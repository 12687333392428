// core
import React, { forwardRef, memo, MouseEvent, Ref, useCallback } from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { Icon } from 'components/basic/Icon/Icon'
import { Loading } from 'components/basic/Loading/Loading'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './Button.scss'

export interface IButtonProps extends IDotappComponentProps {
  /**
   * Button vriant
   */
  variant?: 'icon' | 'text'

  contained?: boolean
  /**
   * Button size
   */
  size?: 'mini' | 'small' | 'normal' | 'large'
  /**
   * Styling of the button, default theme color will be used if none specified.
   */
  color?: 'primary' | 'transparent' | 'white' | 'neutral' | 'success' | 'warning' | 'danger'
  /**
   * OnClick event handler.
   */
  onClick?: (event: MouseEvent) => void
  /**
   * OnMouseDown event handler. We need this event for WysiwygEditor
   */
  onMouseDown?(event: MouseEvent): void
  /**
   * Display loading indicatior instead of text and icon
   */
  loading?: boolean
  /**
   * Disables actions of any type.
   */
  disabled?: boolean
  /**
   * The icon to be shown on the left side
   */
  iconLeft?: string | null
  /**
   * The icon to be shown on trhe right side.
   */
  iconRight?: string | null
  /**
   * The text of a tooltip.
   */
  tooltip?: string
  /**
   * Type of the button used by forms (e.g. 'submit' would submit the HTML form).
   * NOTE: Default is 'button'.
   */
  type?: 'button' | 'reset' | 'submit'

  children?: any
}

export const Button = memo(
  forwardRef(function Button(
    {
      children,
      onClick,
      onMouseDown,
      className,
      loading,
      disabled,
      iconLeft,
      iconRight,
      tooltip,
      type,
      color = 'neutral',
      size = 'normal',
      variant = 'text',
      contained = false,
      classes = {},
    }: IButtonProps,
    ref: Ref<HTMLButtonElement>
  ) {
    const handleClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()

        runCallback(onClick, event)
      },
      [onClick]
    )

    // setting icon(s) if any, otherwise null
    const icons = loading
      ? { left: null, right: null }
      : {
          left: iconLeft ? (
            <div className={classnames(css.iconSection)}>
              <Icon name={iconLeft} />
            </div>
          ) : null,
          right: iconRight ? (
            <div className={classnames(css.iconSection)}>
              <Icon name={iconRight} />
            </div>
          ) : null,
        }

    return (
      // eslint-disable-next-line
      <button
        ref={ref}
        className={classnames(
          css.button,
          disabled ? css.disabled : '',
          variant ? css[variant] : '',
          size ? css[size] : '',
          color ? css[color] : '',
          contained ? css.contained : '',
          className || ''
        )}
        disabled={loading || disabled}
        type={type === 'submit' ? 'submit' : 'button'}
        onClick={handleClick}
        onMouseDown={onMouseDown}
      >
        {/* folowing div is to make it able to use clickable area larger around button, but display background only in this div */}
        <div className={classnames(css.wrap, classes.wrap)}>
          {icons.left}

          {loading ? (
            <Loading />
          ) : children ? (
            <div className={classnames(css.content, classes.content)}>
              {variant === 'text' ? children : null}
            </div>
          ) : null}

          {icons.right}

          {/* If there is a tooltip text specified, display a tooltip with the desired text on hover */}
          {tooltip ? <div className={css.tooltip}>{tooltip}</div> : null}
        </div>
      </button>
    )
  })
)
