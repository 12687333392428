// core
import React, { memo } from 'react'

// components
import { Translation } from 'components/complex/Translation/Translation'

// styles
import * as css from './Footer.scss'

// export interface IFooterProps {}

export const Footer = memo(function Footer(/* {  }: IFooterProps */) {
  return (
    <div className={css.root}>
      <a href="https://www.ambitas.org">
        <Translation keyValue="general.developed_by_ambitas" />
      </a>
    </div>
  )
})
