// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormFieldHelperText_root_3_rFz{padding:8px 0 0 15px;color:#bfbfbf;font-size:9px;line-height:13px}.FormFieldHelperText_root_3_rFz.FormFieldHelperText_inverted_3rSLl{color:#999999}.FormFieldHelperText_root_3_rFz.FormFieldHelperText_inline_31PTa{padding:0 0 0 5px}\n", ""]);
// Exports
exports.locals = {
	"root": "FormFieldHelperText_root_3_rFz",
	"inverted": "FormFieldHelperText_inverted_3rSLl",
	"inline": "FormFieldHelperText_inline_31PTa"
};
module.exports = exports;
