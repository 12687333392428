// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingWrapper_root_9Dde6{width:100%;min-height:100px;flex:1;display:flex;align-items:center;justify-content:center}\n", ""]);
// Exports
exports.locals = {
	"root": "LoadingWrapper_root_9Dde6"
};
module.exports = exports;
