// core
import * as React from 'react'

// components
import { IDotappComponentProps } from 'components'

// styles
import * as css from './Image.scss'

// default img
const defaultImage = require('assets/images/defaultImage.jpg')

export interface IImageProps extends IDotappComponentProps {
  src?: string | null
  alt?: string
  circle?: boolean
  width?: number
  height?: number
}

export const Image = ({ src, alt, circle, className, width, height }: IImageProps) => {
  const imgSource = src || defaultImage

  return (
    <>
      <img
        alt={alt || `Img: ${imgSource}`}
        className={` ${css.image} ${className || ''} ${circle ? css.circle : ''}`}
        height={height}
        src={imgSource}
        width={width}
      />
    </>
  )
}
